import {connect} from "react-redux";
import {createInvoiceRequest, getOrderInfo} from "./invoiceThunks";
import CustomLoadingButton from "../common/CustomLoadingButton";
import React, {useEffect, useRef} from "react";
import CustomDialog from "../common/CustomDialog";
import CustomAccordion from "../common/CustomAccordion";
import GeneralInvoiceInfo from "./GeneralInvoiceInfo";
import {useAlert} from "../common/AlertProvider";
import BuyerInfo from "./BuyerInfo";
import {DEFAULT_INVOICE_DATA} from "./InvoiceConstant";
import {handleApiError, handleErrorMessage, handleSuccessMessage} from "../constants";
import InvoiceItem from "./InvoiceItem";
import {randomId} from "@mui/x-data-grid-generator";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

const CreateInvoice = ({sourceInvoice, open, onClose, onCreatePressed, onGetOrderInfo}) => {
    const {openAlert} = useAlert();
    const [invoiceData, setInvoiceData] = React.useState(sourceInvoice);
    const [expanded, setExpanded] = React.useState('panel0');
    const buyerInfoRef = useRef();
    const generalInfoRef = useRef();
    const navigate = useNavigate();

    const loadInvoiceFromOrder = async (orderId) => {
        const order = await onGetOrderInfo(orderId);
        const customer = order.customer ? order.customer : {};
        // Map the sellItems to the expected structure
        const itemInfo = order.sellItems.map((item, idx) => ({
            id: randomId(),
            itemId: item.itemId,
            lineNumber: idx + 1,
            jewelryId: item.jewelryId || 0,
            itemName: item.name || '',
            unitPrice: item.price || 0,
            quantity: item.goldWeight || 0,
            discount: item.discount || 0,
            itemTotalAmountWithTax: item.amount || 0,
            unit: 'Chỉ',
        }));
        setInvoiceData(prevState => ({
            ...prevState,
            buyerInfo: {
                ...prevState.buyerInfo,
                visitingGuest: false,
                buyerName: customer.nationalName,
                buyerLegalName: customer.nationalName,
                buyerPhoneNumber: customer.phone,
                buyerEmail: customer.email,
                buyerAddressLine: customer.residenceAddress,
                customerId: customer.customerId,
                buyerIdNo: customer.nationalNumber,
            },
            itemInfo: itemInfo,
            invoiceIssuedDate: dayjs(),
            transactionUuid: randomId(),
            orderId: orderId,
            reservationCode: orderId
        }));
    }
    const handleChangeAccordion = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        const updateInvoiceData = () => {
            setInvoiceData(sourceInvoice);
        };
        updateInvoiceData();
    }, [sourceInvoice]);
    const onCreateInvoiceClick = async () => {
        let validated = true;
        const isBuyerInfoValid = buyerInfoRef.current.validateRequest();
        const isGeneralInfoValid = generalInfoRef.current.validateRequest();
        if (!isBuyerInfoValid) {
            setExpanded("panel1");
            validated = false;
        }
        if (invoiceData.itemInfo.length <= 0) {
            validated = false;
            setExpanded("panel2");
            handleErrorMessage('Chi tiết hóa đơn không được để trống', openAlert);
        }
        if (!isGeneralInfoValid) {
            setExpanded("panel0");
            validated = false;
        }
        if (validated) {
            try {
                const response = await onCreatePressed(invoiceData);
                handleSuccessMessage('Hóa đơn điện tử được tạo thành công!', openAlert);
                onClose(response);
                setInvoiceData(
                    DEFAULT_INVOICE_DATA
                );
            } catch (error) {
                handleApiError(error, openAlert);
            }
        }
    }
    const handleClose = async () => {
        navigate('/invoice-mgmt');
        onClose();
    };
    const handleSearchClick = async (orderId) => {
        try {
            if (!orderId || orderId === 0) {
                handleErrorMessage('Mã đơn hàng không bỏ trống', openAlert);
                return;
            }
            await loadInvoiceFromOrder(orderId);
            handleSuccessMessage("Dữ liệu từ đơn hàng được tải thành công!", openAlert);
            setExpanded("panel1");
        } catch (error) {
            console.error("Error while fetching order details:", error)
            if (error && error.response && error.response.status === 404) {
                handleErrorMessage('Không tìm thấy đơn hàng mã [' + orderId + "]", openAlert);
            } else {
                handleApiError(error, openAlert);
            }
        }
    };
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={onCreateInvoiceClick}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Tạo hóa đơn điện tử"/>
        </>
    );
    const pageTitle = (
        <>
            {`Tạo hóa đơn điện tử`}
        </>
    );

    return (
        <>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <CustomAccordion
                    title="Thông tin hóa đơn"
                    panel='panel0'
                    expanded={expanded}
                    onChangeAccordion={handleChangeAccordion}>
                    <GeneralInvoiceInfo invoiceData={invoiceData}
                                        setInvoiceData={setInvoiceData} onSearchOrder={handleSearchClick}
                                        ref={generalInfoRef}/>
                </CustomAccordion>
                <CustomAccordion expanded={expanded}
                                 title="Thông tin khách hàng"
                                 panel='panel1'
                                 onChangeAccordion={handleChangeAccordion}>
                    <BuyerInfo invoiceData={invoiceData}
                               setInvoiceData={setInvoiceData}
                               ref={buyerInfoRef}/>
                </CustomAccordion>
                <CustomAccordion expanded={expanded}
                                 title="Chi tiết hóa đơn"
                                 panel='panel2'
                                 onChangeAccordion={handleChangeAccordion}>
                    <InvoiceItem invoiceData={invoiceData} setInvoiceData={setInvoiceData}></InvoiceItem>
                </CustomAccordion>
            </CustomDialog>
        </>
    )
}
const mapStateToProps = state => ({
    invoices: state.invoices,
});

const mapDispatchToProps = dispatch => ({
    onCreatePressed: invoice => dispatch(createInvoiceRequest(invoice)),
    onGetOrderInfo: orderId => dispatch(getOrderInfo(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice)
