import {Box, Button, Grid} from "@mui/material";
import React, {useState} from "react";
import {
    calculateAmount,
    calculateTotalWeight,
    formatCurrency,
    getGoldBrand,
    getGoldPriceByType,
    getGoldType,
    getResponsiveWidth,
    GoldBrand_Unknown,
    GoldType_Unknown,
    handleErrorMessage,
    handleSuccessMessage,
    handleWarningMessage,
    readGoldWeightUnits,
    SYSTEM_ERROR_MSG
} from "../constants";
import {
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer
} from "@mui/x-data-grid";
import {randomId} from "@mui/x-data-grid-generator";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {DEFAULT_JEWELRY_DATA, fetchJewelryDetails} from "../jewelry/JewelryConstants";
import {DEFAULT_GOLD_TYPE, getTotalAmount, getTotalWeight, ORDER_ITEM_TYPE, ORDER_TYPE} from "./OrderConstant";
import SearchToolbar from "../common/SearchToolbar";
import {useAlert} from "../common/AlertProvider";
import SellItemDetails from "./SellItemDetails";

const sellItemDefault = {
    type: ORDER_ITEM_TYPE.SELLING_ITEM.code,
    orderType: ORDER_TYPE.EXEC_ORDER.code,
    name: '',
    goldType: DEFAULT_GOLD_TYPE,
    goldBrand: GoldBrand_Unknown.code,
    goldWeight: 0,
    gemWeight: 0,
    totalWeight: 0,
    price: getGoldPriceByType(GoldType_Unknown.code).sell,
    procPrice: 0,
    excProcPrice: 0,
    discount: 0,
    amount: 0,
    goldAmount: 0,
}
const SellItem = ({orderData, setOrderData}) => {
    const [sellItemsModes, setSellItemsModes] = useState({});
    const {openAlert} = useAlert();

    function EditToolbar() {
        const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
        const [sellItemData, setSellItemData] = useState(sellItemDefault);
        const buildSellItem = (jewelryData) => {
            try {
                const price = getGoldPriceByType(jewelryData.goldType).sell;
                const discount = 0;
                const procPrice = jewelryData.sellProcPrice;
                const goldWeight = jewelryData.goldWeight;
                const amount = calculateAmount(goldWeight, price, procPrice, discount);
                const goldAmount = calculateAmount(goldWeight, price, 0, 0);
                const totalWeight = calculateTotalWeight(goldWeight, jewelryData.gemWeight);
                return {
                    ...sellItemDefault,
                    type: ORDER_ITEM_TYPE.SELLING_ITEM.code,
                    jewelryId: jewelryData.jewelryId,
                    name: jewelryData.name,
                    goldType: jewelryData.goldType,
                    goldBrand: jewelryData.brand,
                    goldWeight: goldWeight,
                    gemWeight: jewelryData.gemWeight,
                    totalWeight: totalWeight,
                    price: price,
                    procPrice: procPrice,
                    excProcPrice: jewelryData.exchangeProcPrice,
                    amount: amount,
                    goldAmount: goldAmount,
                    orderType: orderData.orderType,
                }
            } catch (error) {
                handleErrorMessage(SYSTEM_ERROR_MSG, openAlert);
            }
        }
        const addJewelryItem = (goldType = DEFAULT_GOLD_TYPE, name = '') => {
            const jewelryData = {
                ...DEFAULT_JEWELRY_DATA,
                name: name,
                goldType: goldType,
            };
            setSellItemData(buildSellItem(jewelryData));
            setOpenDetailsDialog(true);
        };
        const handleSearch = async (filter) => {
            try {
                if (filter && filter.searchWord && filter.searchWord !== '') {
                    const searchWord = filter.searchWord;
                    const jewelryData = await fetchJewelryDetails(searchWord);
                    const itemExist = orderData.sellItems.some(item => item.jewelryId === jewelryData.jewelryId);
                    if (itemExist) {
                        handleWarningMessage(`Món hàng [${jewelryData.jewelryId}] đã tồn tại!`, openAlert);
                        return;
                    }
                    setSellItemData(buildSellItem(jewelryData));
                    setOpenDetailsDialog(true);
                }
            } catch (error) {
                handleWarningMessage("Không tìm thấy. Vui lòng kiểm tra lại!", openAlert);
                console.log(error);
            }
        };
        const handleAddItem = (sellItem) => {
            const id = randomId();
            const indexNumber = orderData.sellItems?.length + 1;
            const jewelryItem = {
                ...sellItem,
                id,
                type: ORDER_ITEM_TYPE.SELLING_ITEM.code,
                index: indexNumber,
                isNew: true
            }
            setOrderData((prevData) => ({
                ...prevData,
                sellItems: [...prevData.sellItems, jewelryItem],
            }));
            handleSuccessMessage(`Món hàng [${jewelryItem.name}] được thêm thành công`, openAlert);
        }
        return (
            <>
                <GridToolbarContainer>
                    <Grid container spacing={2}>
                        <Grid item container xs={getResponsiveWidth(12, 12)}>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <SearchToolbar onSearch={handleSearch} onFindById={() => console.log("")}/>
                            </Grid>
                        </Grid>
                        <Grid item container xs={getResponsiveWidth(12, 12)}>
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '610') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('610', 'Nhẫn kiểu')}>
                                        Nhẫn
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '610') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('610', 'Bông kiểu')}>
                                        Bông
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '610') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('610', 'Vòng kiểu')}>
                                        Vòng
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '610') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('610', 'Lắc kiểu')}>
                                        Lắc
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '610') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('610', 'Vòng Ximen')}>
                                        Ximen
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '610') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('610', 'Mặt kiểu')}>
                                        Mặt
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '610') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('610', 'Dây chuyền')}>
                                        Dây
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '9999') && (
                                <Grid item xs={getResponsiveWidth(3, 3)}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem('9999', 'Nhẫn trơn')}>
                                        9999
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)} container style={{textAlign: 'right', fontWeight: 'bold'}}>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <p>SL: {orderData.sellItems?.length} món{orderData.sellItems ? '/TL: ' + readGoldWeightUnits(getTotalWeight(orderData.sellItems)) : ''}/TT: {formatCurrency(getTotalAmount(orderData.sellItems ? orderData.sellItems : 0))}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
                <SellItemDetails onClose={() => {
                    setOpenDetailsDialog(false);
                }} open={openDetailsDialog} sourceJewelry={sellItemData} onItemAdded={handleAddItem}/>
            </>
        );
    }

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleSaveClick = (id) => () => {
        setSellItemsModes({...sellItemsModes, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        const curItems = orderData.sellItems.filter((row) => row.id !== id);
        setOrderData((prev) => ({...prev, sellItems: curItems}));
    };

    const handleCancelClick = (id) => () => {
        setSellItemsModes({
            ...sellItemsModes,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = orderData.sellItems.find((row) => row.id === id);
        if (editedRow.isNew) {
            const curItems = orderData.sellItems.filter((row) => row.id !== id);
            setOrderData((prev) => ({...prev, sellItems: curItems}));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        const curSellItems = orderData.sellItems.map((row) => (row.id === newRow.id ? updatedRow : row));
        setOrderData((prev) => ({...prev, sellItems: curSellItems}));
        return updatedRow;
    };

    const handleBuyItemRowModelChange = (newRowModesModel) => {
        setSellItemsModes(newRowModesModel);
    };
    const calculateSellAmount = (params) => {
        return calculateAmount(params.row.goldWeight, params.row.price, params.row.procPrice, params.row.discount);
    }
    const itemColumns = [
        {
            field: 'actions',
            type: 'actions',
            align: 'center',
            headerName: 'Tùy chọn',
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = sellItemsModes[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Lưu"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Hủy"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Xóa"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {field: 'index', headerName: 'STT', width: 30, editable: false},
        {field: 'jewelryId', headerName: 'M.Số', width: 100, editable: false},
        {field: 'name', headerName: 'Tên', width: 150, editable: true},
        {
            field: 'goldType',
            headerName: 'Tuổi',
            width: 150,
            type: 'singleSelect',
            valueOptions: Object.values(getGoldType()).map((type) => (
                {value: type.code, label: type.label}
            )),
        },
        {
            field: 'goldBrand',
            headerName: 'Chành',
            width: 120,
            type: 'singleSelect',
            valueOptions: Object.values(getGoldBrand()).map((brand) => (
                {value: brand.code, label: brand.label}
            )),
        },
        {
            field: 'goldWeight',
            headerName: 'TL Vàng',
            type: 'number',
            width: 100,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'gemWeight',
            headerName: 'TL Hột',
            type: 'number',
            width: 100,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'price',
            headerName: 'Đ.Giá',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'goldAmount',
            headerName: 'Thành tiền(chưa công)',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueSetter: (params) => {
                return {
                    ...params.row,
                    goldAmount: calculateAmount(params.row.goldWeight, params.row.price)
                };
            },
        },
        {
            field: 'procPrice',
            headerName: 'T.Công',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueFormatter: (param) => {
                return param.value ? Number(param.value).toLocaleString() : 0;
            },
        },
        {
            field: 'discount',
            headerName: 'G.Giá',
            type: 'number',
            width: 100,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'amount',
            headerName: 'Thành tiền(luôn công)',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueSetter: (params) => {
                return {
                    ...params.row,
                    amount: calculateSellAmount(params)
                };
            },
        },
    ];
    return (
        <>
            <Box sx={{width: 1}}>
                <DataGrid
                    rows={orderData.sellItems}
                    columns={itemColumns}
                    editMode="row"
                    rowModesModel={sellItemsModes}
                    onRowModesModelChange={handleBuyItemRowModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    slots={{
                        toolbar: EditToolbar,
                    }}
                />
            </Box>
        </>
    );
}
export default SellItem;
