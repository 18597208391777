import React, {useEffect, useState} from "react";
import {vi_locale} from 'dayjs/locale/vi';

import axios from '../axiosInstance';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Grid,
    InputAdornment,
    TextField
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {calculateRedeemPawn, mapPawnStatusToText, PAWNS_API_ENDPOINT, suggestPawnInterestValue} from "./PawnConstants";
import {
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    formatDate,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    readMoneyInVietnameseDong,
    setUserTime
} from "../constants";
import RequestMoneyView from "./RequestMoneyView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import ViewPawn from "./ViewPawn";

const ForfeitPawn = ({open, onClose, sourcePawn}) => {
    const {openAlert} = useAlert();
    const [pawnData, setPawnData] = useState(sourcePawn);
    const [isInterestAmountValid, setInterestAmountValid] = useState(true);
    const [isTotalAmountValid, setTotalAmountValid] = useState(true);
    const [isForfeitedAmountValid, setForfeitedAmountValid] = useState(true);
    const calculateForfeitDetails = async (redeemDate) => {
        const redeemData = await calculateRedeemPawn(sourcePawn.pawnId, redeemDate);
        const formattedPawnData = {
            ...redeemData,
            forfeitedDate: convertDayjsDate(redeemData.redeemDate),
            forfeitedReason: pawnData.forfeitedReason,
            forfeitedAmount: parseInt(pawnData.forfeitedAmount, 0),
            forfeitedReturnAmount: calculateReturnAmount(pawnData.forfeitedAmount, pawnData.totalAmount)
        };
        setPawnData(formattedPawnData);
    };
    useEffect(() => {
        setPawnData(sourcePawn);
    }, [open, sourcePawn]);

    const handleForfeitPawn = async () => {
        let validated = true;
        if (pawnData.interestAmount === 0) {
            setInterestAmountValid(false);
            validated = false;
        } else {
            setInterestAmountValid(true);
        }
        if (pawnData.totalAmount === 0) {
            setTotalAmountValid(false);
            validated = false;
        } else {
            setTotalAmountValid(true);
        }

        if (pawnData.forfeitedAmount === 0) {
            setForfeitedAmountValid(false);
            validated = false;
        } else {
            setForfeitedAmountValid(true);
        }
        if (validated) {
            try {
                const response = await axios.post(`${PAWNS_API_ENDPOINT}/${sourcePawn.pawnId}/forfeit`, pawnData);
                handleSuccessMessage('Đơn cầm [' + sourcePawn.pawnId + '] được thanh lý thành công', openAlert);
                onClose(response.data);
            } catch (error) {
                handleApiError(error, openAlert);
            } finally {
                onClose();
            }
        }
    };
    const calculateTotalAmount = (interestAmount) => {
        const totalAmount = parseInt(interestAmount, 0) + parseInt(pawnData.pawnAmount, 0);
        setPawnData((prev) => ({
            ...prev,
            totalAmount: totalAmount
        }));
    }

    const calculateReturnAmount = (forfeitedAmount, totalAmount) => {
        return parseInt(forfeitedAmount, 0) - parseInt(totalAmount, 0);
    }
    useEffect(() => {
        setPawnData((prev) => ({
            ...prev,
            forfeitedReturnAmount: calculateReturnAmount(prev.forfeitedAmount, prev.totalAmount)
        }));
    }, [pawnData.forfeitedAmount, pawnData.totalAmount]);

    const [expanded, setExpanded] = React.useState('panel1');
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleClose = async () => {
        onClose();
    };
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleForfeitPawn}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Thanh lý"/>
        </>
    );
    const pageTitle = (
        <>
            {`Thanh lý đơn cầm số: ${pawnData.pawnId}`}
            <br/>Tình trạng: {mapPawnStatusToText(pawnData.pawnStatus)}
        </>
    );
    return (<div>
        <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
            <ViewPawn sourcePawn={pawnData} onChange={handleChangeAccordion('panel100')}/>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Thông tin thanh lý
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField readOnly disabled
                                       label="Ngày cầm"
                                       value={formatDate(pawnData.pawnDate, DATE_SHORT_FORMAT)}
                                       fullWidth
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                <DatePicker label="Ngày thanh lý"
                                            value={pawnData.forfeitedDate}
                                            onChange={(date) => {
                                                const selectedDate = setUserTime(date);
                                                setPawnData((prev) => ({...prev, forfeitedDate: selectedDate}));
                                                calculateForfeitDetails(selectedDate);
                                            }}
                                            fullWidth
                                            format={DATE_SHORT_FORMAT}
                                            slotProps={{field: {shouldRespectLeadingZeros: true}, textField: { fullWidth: true }}}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField readOnly disabled
                                       label="Số tiền cầm"
                                       value={Number(pawnData.pawnAmount).toLocaleString()}
                                       fullWidth
                                       InputProps={{
                                           endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                       }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <Autocomplete
                                freeSolo
                                value={!isNaN(pawnData.interestAmount) ? Number(pawnData.interestAmount).toLocaleString() : '0'}
                                onChange={(event, selectedValue) => {
                                    const numericInput = selectedValue ? selectedValue.value : 0;
                                    setPawnData((prev) => ({
                                        ...prev,
                                        interestAmount: numericInput,
                                        totalAmount: (Number(numericInput) + Number(prev.pawnAmount)),
                                    }));
                                    calculateTotalAmount(numericInput);
                                }}
                                options={suggestPawnInterestValue(!isNaN(sourcePawn.interestAmount) ? sourcePawn.interestAmount : 0).map((option) => ({
                                    label: Number(option).toLocaleString(),
                                    value: parseInt(option),
                                }))}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Tiền lãi"
                                        value={!isNaN(pawnData.interestAmount) ? Number(pawnData.interestAmount).toLocaleString() : '0'}
                                        onChange={(e) => {
                                            const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                            setPawnData((prev) => ({
                                                ...prev,
                                                interestAmount: numericInput,
                                            }));
                                            calculateTotalAmount(numericInput);
                                            setInterestAmountValid(true);
                                        }}
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            endAdornment: (
                                                <InputAdornment position="end">vnđ</InputAdornment>
                                            ),
                                        }}
                                        error={!isInterestAmountValid}
                                    />}
                            />
                        < /Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField
                                label="Tổng(tiền cầm + tiền lãi):"
                                value={Number(pawnData.totalAmount).toLocaleString()}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                    setPawnData((prev) => ({
                                        ...prev, totalAmount: numericInput,
                                    }));
                                    setTotalAmountValid(true);
                                }}
                                fullWidth
                                InputProps={{endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),}}
                                error={!isTotalAmountValid}
                            />
                        < /Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField
                                label="Giá thanh lý"
                                value={Number(pawnData.forfeitedAmount).toLocaleString()}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                    setPawnData((prev) => ({
                                        ...prev,
                                        forfeitedAmount: numericInput,
                                    }));
                                }}
                                fullWidth
                                InputProps={{endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),}}
                                error={!isForfeitedAmountValid}
                            />
                        < /Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField readOnly disabled
                                       label="Còn lại(Thanh lý - tổng)"
                                       value={Number(pawnData.forfeitedReturnAmount).toLocaleString()}
                                       fullWidth
                                       InputProps={{
                                           endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                       }}
                            />
                        < /Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField
                                label="Lý do thanh lý"
                                value={pawnData.forfeitedReason}
                                fullWidth
                                onChange={(e) => setPawnData((prev) => ({
                                    ...prev, forfeitedReason: e.target.value,
                                }))}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <RequestMoneyView reqMoneyData={pawnData.reqMoneys} editable={false}/>
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)} style={{textAlign: 'right', fontWeight: "bold"}}>
                            <p>Số tiền còn lại: {Number(pawnData.forfeitedReturnAmount).toLocaleString()} vnđ.</p>
                            <p>{readMoneyInVietnameseDong(pawnData.forfeitedReturnAmount)}.</p>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </CustomDialog>
    </div>)
};
export default ForfeitPawn;

