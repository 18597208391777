import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import {Box, Container, Typography} from "@mui/material";
import Login from "../auth/Login";
import ChangePassword from "../auth/ChangePassword";
import PawnGrid from "../pawn/PawnGrid";
import OrderGrid from "../order/OrderGrid";
import CustomerGrid from "../customer/CustomerGrid";
import JewelryGrid from "../jewelry/JewelryGrid";
import SystemBoard from "../system/SystemBoard";
import UserGrid from "../user/UserGrid";
import Dashboard from "../dashboard/Dashboard";
import Logout from "../auth/Logout";
import InvoiceGrid from "../invoice/InvoiceGrid";
import NotAuthorized from "../auth/NotAuthorized";
import {requiredPermission} from "../auth/auth";
import GoldPriceTable from "../system/GoldPriceTable";

const Home = () => {
    return (
        <Container maxWidth={false}>
            <Dashboard/>
        </Container>
    );
};
const LoginRoute = () => {
    return (
        <Login/>
    );
}

const ChangePasswordRoute = () => {
    return (
        <ChangePassword/>
    );
}
const PawnMgmtRoute = () => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Cầm đồ
            </Typography>
            <Box mt={2}>
                <PawnGrid/>
            </Box>
        </Container>
    );
};

const OrderMgmtRoute = () => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                Mua bán, trao đổi
            </Typography>
            <Box mt={2}>
                <OrderGrid/>
            </Box>
        </Container>
    );
};

const CustomerMgmtRoute = () => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Khách hàng
            </Typography>
            <Box mt={2}>
                <CustomerGrid/>
            </Box>
        </Container>
    );
};
const InventoryMgmtRoute = () => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>

            </Typography>
            <Box mt={2}>
                <JewelryGrid/>
            </Box>
        </Container>
    );
};
const SystemMgmtRoute = () => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Hệ thống
            </Typography>
            <Box mt={2}>
                <SystemBoard/>
            </Box>
        </Container>
    );
};
const UserMgmtRoute = () => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Người dùng
            </Typography>
            <Box mt={2}>
                <UserGrid/>
            </Box>
        </Container>
    );
};

const GoldPriceRoute = ({onToggleFullScreen}) => {
    return (
        <Container maxWidth={false}>
            <Box mt={2}>
                <GoldPriceTable onToggleFullScreen={onToggleFullScreen}/>
            </Box>
        </Container>
    );
}

const InvoiceMgmtRoute = () => {
    return (
        <Container maxWidth={false}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                Hóa đơn điện tử
            </Typography>
            <Box mt={2}>
                <InvoiceGrid/>
            </Box>
        </Container>
    );
};

const AppRoutes = ({isAuthenticated, onToggleFullScreen}) => {
    return (
        <Routes>
            <Route path='/' element={<ProtectedRoute auth={isAuthenticated}><Home/></ProtectedRoute>}/>
            <Route path='/dashboard' element={<ProtectedRoute auth={isAuthenticated}><Home/></ProtectedRoute>}/>
            <Route path='/login' element={<LoginRoute/>}/>
            <Route path='/pawn-mgmt' element={<ProtectedRoute auth={isAuthenticated}
                                                              requiredPermission={requiredPermission('/pawn-mgmt')}><PawnMgmtRoute/></ProtectedRoute>}/>
            <Route path='/customer-mgmt' element={<ProtectedRoute auth={isAuthenticated}
                                                                  requiredPermission={requiredPermission('/customer-mgmt')}><CustomerMgmtRoute/></ProtectedRoute>}/>
            <Route path='/inventory-mgmt' element={<ProtectedRoute auth={isAuthenticated}
                                                                   requiredPermission={requiredPermission('/inventory-mgmt')}><InventoryMgmtRoute/></ProtectedRoute>}/>
            <Route path='/order-mgmt' element={<ProtectedRoute auth={isAuthenticated}
                                                               requiredPermission={requiredPermission('/order-mgmt')}><OrderMgmtRoute/></ProtectedRoute>}/>
            <Route path='/invoice-mgmt' element={<ProtectedRoute auth={isAuthenticated}
                                                                 requiredPermission={requiredPermission('/invoice-mgmt')}><InvoiceMgmtRoute/></ProtectedRoute>}/>
            <Route path='/system-mgmt' element={<ProtectedRoute auth={isAuthenticated}
                                                                requiredPermission={requiredPermission('/system-mgmt')}><SystemMgmtRoute/></ProtectedRoute>}/>
            <Route path='/user-mgmt' element={<ProtectedRoute auth={isAuthenticated}
                                                              requiredPermission={requiredPermission('/user-mgmt')}><UserMgmtRoute/></ProtectedRoute>}/>
            <Route path='/gold-price' element={<ProtectedRoute auth={isAuthenticated}
                                                               requiredPermission={requiredPermission('/gold-price')}><GoldPriceRoute
                onToggleFullScreen={onToggleFullScreen}/></ProtectedRoute>}/>
            <Route path='/change-password' element={<ChangePasswordRoute/>}/>
            <Route path='/logout' element={<Logout/>}/>
            <Route path="/not-authorized" element={<NotAuthorized/>}/>
        </Routes>
    );
}

export default AppRoutes;
