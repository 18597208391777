import React, {useEffect, useState} from 'react';
import {vi_locale} from 'dayjs/locale/vi';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import axios from '../axiosInstance';
import {
    calculateRedeemPawn,
    InterestTypes,
    mapPawnStatusToText,
    PAWNS_API_ENDPOINT,
    suggestPawnInterestValue
} from "./PawnConstants";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {
    DATE_SHORT_FORMAT,
    formatDate,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    readMoneyInVietnameseDong,
    setUserTime
} from "../constants";
import RequestMoneyView from "./RequestMoneyView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import ViewPawn from "./ViewPawn";

const RedeemPawn = ({open, onClose, sourcePawn}) => {
    const {openAlert} = useAlert();
    const [pawnData, setPawnData] = useState(sourcePawn);
    const [isInterestAmountValid, setInterestAmountValid] = useState(true);
    const [isTotalAmountValid, setTotalAmountValid] = useState(true);
    useEffect(() => {
        setPawnData(sourcePawn);
    }, [open, sourcePawn]);
    const calculateRedeemDetails = async (redeemDate) => {
        const redeemData = await calculateRedeemPawn(sourcePawn.pawnId, redeemDate);
        setPawnData(redeemData);
    };

    const handleRedeem = async () => {
        try {
            const requestBody = {
                ...pawnData,
                additionalAmount: 0,
                pawnStatus: 'REDEEMED',
                requestType: 'REDEEMED',
            };
            const response = await axios.put(`${PAWNS_API_ENDPOINT}/${pawnData.pawnId}`, requestBody);
            handleSuccessMessage('Đơn cầm [' + response.data.pawnId + '] được chuộc thành công', openAlert);
            onClose(response.data);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleRedeem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Chuộc đồ"/>
        </>
    );
    const pageTitle = (
        <>
            {`Chuộc đồ đơn số: ${pawnData.pawnId}`}
            <br/>Tình trạng: {mapPawnStatusToText(pawnData.pawnStatus)}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <ViewPawn sourcePawn={pawnData} onChange={handleChangeAccordion("panel100")}/>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin chuộc
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={getResponsiveWidth()}>
                                <TextField readOnly disabled
                                           label="Ngày cầm"
                                           value={formatDate(pawnData.pawnDate, DATE_SHORT_FORMAT)}
                                           fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(6, 6)}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                    <DatePicker label="Ngày chuộc"
                                                value={pawnData.redeemDate}
                                                onChange={(date) => {
                                                    const selectedDate = setUserTime(date);
                                                    setPawnData((prev) => ({...prev, redeemDate: selectedDate}));
                                                    calculateRedeemDetails(selectedDate);
                                                }}
                                                format={DATE_SHORT_FORMAT}
                                                slotProps={{field: {shouldRespectLeadingZeros: true}, textField: { fullWidth: true }}}
                                    />
                                </LocalizationProvider>
                            < /Grid>
                            <Grid item xs={getResponsiveWidth(6, 6)}>
                                <TextField
                                    label="Số ngày cầm" readOnly disabled
                                    value={pawnData.heldDays}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">ngày</InputAdornment>),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(6, 6)}>
                                <TextField
                                    label="Lãi suất" readOnly disabled
                                    value={pawnData.interestRate}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <Select
                                    value={pawnData.interestDaysPerMonth}
                                    fullWidth
                                    readOnly disabled
                                    onChange={(e) => {
                                        setPawnData((prev) => ({
                                            ...prev,
                                            interestDaysPerMonth: e.target.value,
                                        }));
                                    }}
                                >
                                    {Object.values(InterestTypes).map((interestType) => (
                                        <MenuItem key={interestType.code} value={interestType.code}>
                                            {interestType.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <TextField readOnly disabled
                                           label="Số tiền cầm"
                                           value={Number(pawnData.pawnAmount).toLocaleString()}
                                           fullWidth
                                           InputProps={{
                                               endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                           }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <Autocomplete
                                    freeSolo
                                    value={!isNaN(pawnData.interestAmount) ? Number(pawnData.interestAmount).toLocaleString() : '0'}
                                    onChange={(event, selectedValue) => {
                                        const numericInput = selectedValue ? selectedValue.value : 0;
                                        setPawnData((prev) => ({
                                            ...prev,
                                            interestAmount: numericInput,
                                            totalAmount: (Number(numericInput) + Number(prev.pawnAmount)),
                                        }));
                                    }}
                                    options={suggestPawnInterestValue(!isNaN(sourcePawn.interestAmount) ? sourcePawn.interestAmount : 0).map((option) => ({
                                        label: Number(option).toLocaleString(),
                                        value: parseInt(option),
                                    }))}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Tiền lãi"
                                            value={!isNaN(pawnData.interestAmount) ? Number(pawnData.interestAmount).toLocaleString() : '0'}
                                            onChange={(e) => {
                                                const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                                setPawnData((prev) => ({
                                                    ...prev,
                                                    interestAmount: numericInput,
                                                    totalAmount: (Number(numericInput) + Number(prev.pawnAmount)),
                                                }));
                                                setInterestAmountValid(true);
                                            }}
                                            fullWidth
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                                endAdornment: (
                                                    <InputAdornment position="end">vnđ</InputAdornment>
                                                ),
                                            }}
                                            error={!isInterestAmountValid}
                                        />}
                                />
                            < /Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <TextField
                                    label="Tổng(tiền cầm + tiền lãi + phí):"
                                    value={Number(pawnData.totalAmount).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                        setPawnData((prev) => ({
                                            ...prev, totalAmount: numericInput,
                                        }));
                                        setTotalAmountValid(true);
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                    }}
                                    error={!isTotalAmountValid}
                                />
                            < /Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <RequestMoneyView reqMoneyData={pawnData.reqMoneys} editable={false}/>
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)} style={{textAlign: 'right', fontWeight: "bold"}}>
                                <p>Số tiền phải thu: {Number(pawnData.totalAmount).toLocaleString()} vnđ.</p>
                                <p>{readMoneyInVietnameseDong(pawnData.totalAmount)}.</p>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </CustomDialog>
        </div>
    );
};

export default RedeemPawn;
