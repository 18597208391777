import React, {useEffect, useState} from 'react';
import {vi_locale} from 'dayjs/locale/vi';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Grid,
    InputAdornment,
    TextField,
} from '@mui/material';
import axios from '../axiosInstance';
import {
    calculatePawnDueDate,
    calculateRedeemPawn,
    mapPawnStatusToText,
    PAWNS_API_ENDPOINT,
    suggestPawnInterestValue
} from "./PawnConstants";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    formatCustomerName,
    formatDate,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    readMoneyInVietnameseDong,
    setUserTime
} from "../constants";
import RequestMoneyView from "./RequestMoneyView";
import {handlePrinting} from "./PawnStamp";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useAlert} from "../common/AlertProvider";
import CustomLoadingButton from "../common/CustomLoadingButton";
import CustomDialog from "../common/CustomDialog";
import ViewPawn from "./ViewPawn";

const ExtendPawn = ({open, onClose, sourcePawn}) => {
    const {openAlert} = useAlert();
    const [pawnData, setPawnData] = useState(sourcePawn);
    const [isInterestAmountValid, setInterestAmountValid] = useState(true);
    useEffect(() => {
        setPawnData(sourcePawn);
    }, [open, sourcePawn]);
    const calculateRedeemDetails = async (redeemDate) => {
        const redeemData = await calculateRedeemPawn(sourcePawn.pawnId, redeemDate, true);
        setPawnData({
            ...redeemData,
            extendDate: convertDayjsDate(redeemDate),
            extendDueDate: convertDayjsDate(calculatePawnDueDate(redeemDate)),
        });
    };
    const calculateTotalAmount = (interestAmount) => {
        const totalAmount = parseInt(interestAmount, 0) + parseInt(pawnData.pawnAmount, 0);
        setPawnData((prev) => ({
            ...prev,
            totalAmount: totalAmount
        }));
    }
    const handleExtendPawn = async () => {
        try {
            const requestBody = {
                ...pawnData,
                additionalAmount: 0,
                extendDate: pawnData.redeemDate,
                pawnStatus: 'REDEEMED',
            };
            const response = await axios.put(`${PAWNS_API_ENDPOINT}/${pawnData.pawnId}/extend`, requestBody);
            handleSuccessMessage('Đơn cầm [' + pawnData.pawnId + '] được gia hạn thành công, đơn cầm mới số [' + response.data.pawnId + ']', openAlert);
            onClose(response.data);
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const handleSavePrintButton = async () => {
        const savedPawn = await handleExtendPawn();
        await handlePrinting({
            ...savedPawn,
            pawnDate: convertDayjsDate(savedPawn.pawnDate),
            pawnDueDate: convertDayjsDate(savedPawn.pawnDueDate),
            customerName: formatCustomerName(savedPawn.customer),
        });
    }
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleClose = async () => {
        onClose();
    };
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleSavePrintButton}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Gia hạn và in"/>
            <CustomLoadingButton variant="contained" onClick={handleExtendPawn} sx={{minWidth: 170, marginBottom: 1}}
                                 fullWidth buttonLabel="Chỉ Gia hạn"/>
        </>
    );
    const pageTitle = (
        <>
            {`Đóng lãi đơn số: ${pawnData.pawnId}`}
            <br/>Tình trạng: {mapPawnStatusToText(pawnData.pawnStatus)}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <ViewPawn sourcePawn={pawnData} onChange={handleChangeAccordion(expanded)}/>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin gia hạn
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField readOnly disabled
                                           label="Ngày cầm"
                                           value={formatDate(pawnData.pawnDate, DATE_SHORT_FORMAT)}
                                           fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField readOnly disabled
                                           label="Số tiền cầm"
                                           value={Number(pawnData.pawnAmount).toLocaleString()}
                                           fullWidth
                                           InputProps={{
                                               endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                           }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <Autocomplete
                                    freeSolo
                                    value={!isNaN(pawnData.interestAmount) ? Number(pawnData.interestAmount).toLocaleString() : '0'}
                                    onChange={(event, selectedValue) => {
                                        const numericInput = selectedValue ? selectedValue.value : 0;
                                        setPawnData((prev) => ({
                                            ...prev,
                                            interestAmount: numericInput,
                                            totalAmount: (Number(numericInput) + Number(prev.pawnAmount)),
                                        }));
                                    }}
                                    options={suggestPawnInterestValue(!isNaN(sourcePawn.interestAmount) ? sourcePawn.interestAmount : 0).map((option) => ({
                                        label: Number(option).toLocaleString(),
                                        value: parseInt(option),
                                    }))}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Tiền lãi"
                                            value={!isNaN(pawnData.interestAmount) ? Number(pawnData.interestAmount).toLocaleString() : '0'}
                                            onChange={(e) => {
                                                const numericInput = e.target.value.replace(/[^0-9]/g, '');
                                                setPawnData((prev) => ({
                                                    ...prev,
                                                    interestAmount: numericInput,
                                                }));
                                                calculateTotalAmount(numericInput);
                                                setInterestAmountValid(true);
                                            }}
                                            fullWidth
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                                endAdornment: (
                                                    <InputAdornment position="end">vnđ</InputAdornment>
                                                ),
                                            }}
                                            error={!isInterestAmountValid}
                                        />}
                                />
                            < /Grid>
                            <Grid item xs={getResponsiveWidth(6, 6)}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                    <DatePicker label="Ngày gia hạn"
                                                value={pawnData.redeemDate ? pawnData.redeemDate : null}
                                                onChange={(date) => {
                                                    const selectedDate = setUserTime(date);
                                                    setPawnData((prev) => ({...prev, redeemDate: selectedDate}));
                                                    calculateRedeemDetails(selectedDate);
                                                }}
                                                format={DATE_SHORT_FORMAT}
                                                slotProps={{field: {shouldRespectLeadingZeros: true}, textField: { fullWidth: true }}}
                                    />
                                </LocalizationProvider>
                            < /Grid>
                            <Grid item xs={getResponsiveWidth(6, 6)}>
                                <TextField
                                    label="Số ngày cầm" readOnly disabled
                                    value={pawnData.heldDays}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">ngày</InputAdornment>),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                    <DatePicker label="Ngày đến hẹn mới"
                                                value={pawnData.extendDueDate ? pawnData.extendDueDate : null}
                                                onChange={(date) => {
                                                    setPawnData((prev) => ({
                                                        ...prev,
                                                        extendDueDate: setUserTime(date)
                                                    }));
                                                }}
                                                format={DATE_SHORT_FORMAT}
                                                slotProps={{field: {shouldRespectLeadingZeros: true}, textField: { fullWidth: true }}}
                                    />
                                </LocalizationProvider>
                            < /Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <RequestMoneyView reqMoneyData={pawnData.reqMoneys} editable={false}/>
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)} style={{textAlign: 'right', fontWeight: "bold"}}>
                                <p>Số tiền phải thu: {Number(pawnData.interestAmount).toLocaleString()} vnđ.</p>
                                <p>{readMoneyInVietnameseDong(pawnData.interestAmount)}.</p>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </CustomDialog>
        </div>
    );
};

export default ExtendPawn;
