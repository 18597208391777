import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useEffect, useRef, useState} from "react";

const SearchToolbar = ({onSearch: onSearchFunction, onFindById: onFindByIdFunction}) => {
    const [filter, setFilter] = useState({searchWord: ''});
    const canvasRef = useRef(null);
    const cameraRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.willReadFrequently = true;
        }
    }, []);
    const handleSearchClick = async () => {
        await onSearchFunction(filter);
    };
    return (
        <div>
            <TextField
                label="Nhập vào để tìm kiếm"
                type="search"
                size="small"
                variant="standard"
                value={filter.searchWord}
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        const fetchData = async () => {
                            await handleSearchClick();
                        }
                        fetchData();
                    }
                }}
                onChange={(e) => {
                    setFilter((prev) => ({
                        ...prev,
                        searchWord: e.target.value,
                    }));
                }}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{cursor: 'pointer'}} onClick={handleSearchClick}/>
                            <div id="barcode-scanner">
                                {/*<QrCode2Icon style={{cursor: 'pointer'}} onClick={handleScanClick}/>*/}
                            </div>
                        </InputAdornment>
                    )
                }}
            />
            <div ref={cameraRef}>
                <canvas ref={canvasRef} style={{display: 'none'}}/>
            </div>
        </div>

    )
}
export default SearchToolbar;
