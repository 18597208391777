import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AppBar, Box, createTheme, Drawer, List, ThemeProvider} from '@mui/material';
import {AlertProvider} from "./components/common/AlertProvider";
import {isUserAuthenticated, logout} from "./components/auth/auth";
import CircularIndeterminate from "./components/common/CircularIndeterminate";
import AppRoutes from "./components/route/AppRoutes";
import AppToolbar from "./components/common/AppToolbar";
import NavigationMenu from "./components/common/NavigationMenu";
import HomeIcon from "@mui/icons-material/Home";
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import StoreIcon from '@mui/icons-material/Store';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import BadgeIcon from '@mui/icons-material/Badge';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {PermissionProvider} from "./components/route/PermissionProvider";
import {DEFAULT_PRIMARY_COLOR, LOCAL_STORAGE_PRIMARY_COLOR} from "./components/constants";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const menuItems = [
    {label: 'Trang chủ', path: '/', icon: <HomeIcon/>},
    {label: 'Cầm đồ', path: '/pawn-mgmt', icon: <MonetizationOnIcon/>},
    {label: 'Khách hàng', path: '/customer-mgmt', icon: <ManageAccountsIcon/>},
    {label: 'Mua/Bán', path: '/order-mgmt', icon: <ShopTwoIcon/>},
    {label: 'Hóa đơn', path: '/invoice-mgmt', icon: <ReceiptIcon/>},
    {label: 'Xuất/Nhập', path: '/inventory-mgmt', icon: <StoreIcon/>},
    {label: 'Hệ thống', path: '/system-mgmt', icon: <SettingsApplicationsIcon/>},
    {label: 'Người dùng', path: '/user-mgmt', icon: <BadgeIcon/>},
    {label: 'Bảng giá', path: '/gold-price', icon: <PriceChangeIcon/>},
];

const App = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [primaryColor, setPrimaryColor] = useState(DEFAULT_PRIMARY_COLOR);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        async function checkAuthentication() {
            const authenticated = await isUserAuthenticated();
            setIsAuthenticated(authenticated);
            setLoading(false);
        }

        checkAuthentication();

        const savedColor = localStorage.getItem(LOCAL_STORAGE_PRIMARY_COLOR);
        if (savedColor) {
            setPrimaryColor(savedColor);
        }

        const handleStorageChange = (event) => {
            const updatedColor = localStorage.getItem(LOCAL_STORAGE_PRIMARY_COLOR);
            if (updatedColor) {
                setPrimaryColor(updatedColor);
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const theme = createTheme({
        palette: {
            primary: {
                main: primaryColor,
            },
        },
    });

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.log("Error while processing logout:", error);
        } finally {
            window.location.href = '/login';
        }
    };

    const handleToggleFullScreen = (isFullScreen) => {
        setIsFullScreen(isFullScreen);
    };

    if (loading) {
        return <CircularIndeterminate/>;
    }

    return (
        <AlertProvider>
            <PermissionProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                            {!isFullScreen && (
                                <AppBar position="static">
                                    <AppToolbar
                                        menuItems={menuItems}
                                        isAuthenticated={isAuthenticated}
                                        onDrawerToggle={handleDrawerToggle}
                                        onLogout={handleLogout}
                                    />
                                </AppBar>
                            )}
                            {!isFullScreen && (
                                <Drawer
                                    variant="temporary"
                                    anchor="left"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true,
                                    }}
                                >
                                    <List>
                                        <NavigationMenu
                                            menuItems={menuItems}
                                            isAuthenticated={isAuthenticated}
                                            onDrawerToggle={handleDrawerToggle}
                                            onLogout={handleLogout}
                                        />
                                    </List>
                                </Drawer>
                            )}
                            <Box component="main" sx={{flexGrow: 1}}>
                                <AppRoutes isAuthenticated={isAuthenticated}
                                           onToggleFullScreen={handleToggleFullScreen}/>
                            </Box>
                        </Box>
                    </Router>
                </ThemeProvider>
            </PermissionProvider>
        </AlertProvider>
    );
};

export default App;
